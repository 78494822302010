export default{
    data(){
        return{
            fid:1,
            index:0,
            order_id:'',
            order_num:'',
            order_name:'',
            show:false,
            popType:4,
            shType:'1',
            orderSn:'',
            jgType:'',
            status:[
                {
                    text:'售后申请中',
                    id:1,
                    state:3
                },{
                    text:'审核通过',
                    id:2,
                    state:4
                },{
                    text:'售后中',
                    id:3,
                    state:5
                },{
                    text:'售后拒绝',
                    id:4,
                    state:6
                }
            ],
            theader:['订单号','加工分类','图纸','金额','状态','操作']
        }
    },
    created(){
       
    },
    methods: {
        openEvaluate:function(order_id,order_num){
            this.order_id = order_id +'';
            this.order_num = order_num +'';
            this.show = true;
            this.popType = 4;
        },
        oepnAfterSales:function(order_id){
            this.order_id = order_id +'';
            this.show = true;
            this.popType = 3;
        },
        oepnWl:function(order_id,shType,jgType,orderSn){
            this.show = true;
            this.order_id = order_id +'';
            this.popType = 5;
            this.shType =shType + '';
            this.orderSn = orderSn +'';
            this.jgType = jgType+'';
        },
        closepop:function(){
            this.show = false
            this.orderDetail(this.$route.query.id);
        },
        viewRefused:function(id){
            this.$post('/api/order/viewRefused.html', {
                token:true,
                id:id
            }).then((res) => {
                if (res.code == 200) {
                    console.log(res.data.refused_content )
                    this.$alert(res.data.refused_content  || '未填写原因，请联系客服', {
                        confirmButtonText: '确定',
                        // callback: action => {
                          
                        // }
                      });
                }else{
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        }
    }
}