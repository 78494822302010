export default{
        data(){
            return{
                info:{},
                order_id:''
            }
        },
        created() {
            this.order_id = this.$route.query.id
    
            this.orderDetail(this.order_id)
        },
        methods: {
            orderDetail:function(id){
                this.$post('/api/order/userAfterOrderDetail.html', {
                    token: true,
                    id: id
                }).then((res) => {
                    console.log(res)
                    if (res.code == 200) {
                        this.info = res.data
                    } else {
                        return this.$message(res.msg);
                    }
    
                }).finally(() => {
    
                })
            },
            viewRefused:function(id){
                this.$post('/api/order/viewRefused.html', {
                    token:true,
                    id:id
                }).then((res) => {
                    if (res.code == 200) {
                        console.log(res.data.refused_content )
                        this.$alert(res.data.refused_content  || '未填写原因，请联系客服', {
                            confirmButtonText: '确定',
                            // callback: action => {
                              
                            // }
                          });
                    }else{
                        return this.$message(res.msg);
                    }
                }).finally(() => {
    
                })
            },
            dowfj:function(url){
                window.open(url)
            },
            toorder:function(){
                this.$router.push({path:'/user-center/order'})
            }
    }
}