<template>
  <div class="sale-detail">
    <div class="c-width">
      <div class="path">
        当前位置：<i @click="toorder">我的专区 / 我的订单</i> /<span>订单查询</span>
      </div>
      <div class="order-info">
        <div class="cf info-top">
          <div class="h5 fl" v-for="(item, index) in status" :key="index">
            <span v-if="info.aftersales.status == item.state"
              >当前状态：{{ item.text }}</span
            >
          </div>
          <div class="btn-list fr">
            <button
              v-if="  info.aftersales.status == 4"
              class="btn"
              @click="
                oepnWl(
                  info.aftersales.id,
                  info.aftersales.after_type,
                  info.aftersales.category_name,
                  info.aftersales.order_sn
                )
              "
            >
              填写发货物流信息
            </button>
            <button
              v-if="  info.aftersales.status == 6"
              class="btn"
              @click="
                viewRefused(
                  info.aftersales.id,
                )
              "
            >
              查看拒绝原因
            </button>
             <button
              v-if="  info.aftersales.status == 1"
              class="btn"
              @click="
                oepnAfterSales(
                  info.aftersales.id,
                )
              "
            >
              提交售后申请
            </button>
          </div>
        </div>
        <div class="hint-text">订单信息</div>
        <div class="info-detail cf">
          <div class="l-box fl">
            <p>
              <span class="l-span"
                >申请时间：{{ info.aftersales.ctime || "暂无数据" }}</span
              ><span></span>
            </p>
            <p>
              <span class="l-span"
                >订单编号：{{ info.aftersales.order_sn || "暂无数据" }}</span
              ><span></span>
            </p>
            <p>
              <span class="l-span"
                >发货单号：{{ info.aftersales.invoice_sn || "暂无数据" }}</span
              ><span></span>
            </p>
            <p>
              <span class="l-span"
                >客户名称：{{ info.aftersales.linkman || "暂无数据" }}</span
              ><span></span>
            </p>
            <p>
              <span class="l-span"
                >客户电话：{{
                  info.aftersales.linkman_phone || "暂无数据"
                }}</span
              ><span></span>
            </p>
            <p>
              <span class="l-span"
                >退还金额：{{
                  info.aftersales.refund_mondey || "暂无数据"
                }}</span
              ><span></span>
            </p>
            <p>
              <span class="l-span"
                >收货地址：{{ info.aftersales.address || "暂无数据" }}</span
              ><span></span>
            </p>
            <p><span class="l-span">订单状态：售后订单</span><span></span></p>
          </div>
          <div
            class="l-box fl"
            v-if="
              (info.aftersales.status == 4 || info.aftersales.status == 3) &&
              info.aftersales.after_type == 2
            "
          >
            <p>
              <span class="l-span"
                >退款账号：{{ info.aftersales.account || "待填写" }}</span
              ><span class="r-span"></span>
            </p>
            <p>
              <span class="l-span"
                >开户行：{{ info.aftersales.bank || "待填写" }}</span
              ><span class="r-span"></span>
            </p>
            <p>
              <span class="l-span"
                >收款人：{{ info.aftersales.payee || "待填写" }}</span
              ><span class="r-span"></span>
            </p>
            <p>
              <span class="l-span"
                >联系方式：{{ info.aftersales.payee_phone || "待填写" }}</span
              ><span class="r-span"></span>
            </p>
            <p>
              <span class="l-span"
                >退货物流公司：{{
                  info.aftersales.re_courier_company || "待填写"
                }}</span
              ><span class="r-span"></span>
            </p>
            <p>
              <span class="l-span"
                >退货物流单号：{{
                  info.aftersales.re_courier_num || "待填写"
                }}</span
              ><span class="r-span"></span>
            </p>
          </div>
        </div>
      </div>

      <div class="step">
        <div class="h6">进度信息</div>
        <div class="table">
          <ul class="t-header cf">
            <li
              class="t-item fl"
              v-for="(item, index) in steoHeader"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
          <ul
            class="tb-item cf"
            v-for="(item, index) in info.delivery_order"
            :key="index"
          >
            <li class="t-item fl">{{ item.invoice_sn }}</li>
            <li class="t-item fl">{{ item.name }}</li>
            <li class="t-item fl">{{ item.goods_nums }}</li>
            <li class="t-item fl">{{ item.courier_company }}</li>
            <li class="t-item fl">{{ item.courier_num }}</li>
          </ul>
        </div>
      </div>

      <div class="drawing">
        <div class="h6">图纸信息</div>
        <div class="table">
          <ul class="t-header cf">
            <li class="t-item fl" v-for="(item, index) in drawing" :key="index">
              {{ item }}
            </li>
          </ul>
          <ul
            class="tb-item cf"
            v-for="(item, index) in info.img_lists"
            :key="index"
          >
            <li class="t-item fl">{{ index + 1 }}</li>
            <li class="t-item fl">{{ item.img_no || "暂无数据" }}</li>
            <li class="t-item fl">{{ item.name || "暂无数据" }}</li>
            <li class="t-item fl">{{ item.file_format || "暂无数据" }}</li>
            <li class="t-item fl" @click="dowfj(item.url)">
              {{ item.img_no || "暂无数据" }}
            </li>
            <li class="t-item fl">{{ item.version_num || "暂无数据" }}</li>
            <li class="t-item fl">{{ item.file_size || "暂无数据" }}</li>
            <li class="t-item fl">{{ item.file_content || "暂无数据" }}</li>
          </ul>
        </div>
      </div>
    </div>
    <afterSale
      :show="show"
      :type="popType"
      :orderId="order_id"
      @closepop="closepop"
    ></afterSale>
    <evaluate
      :show="show"
      :type="popType"
      :orderId="order_id"
      :orderNum="order_num"
      @closepop="closepop"
    ></evaluate>
    <!-- <reason></reason> -->
    <saletype
      :show="show"
      :type="popType"
      :orderId="order_id"
      :shType="shType"
      :jgType="jgType"
      :orderSn="orderSn"
      @closepop="closepop"
    ></saletype>
  </div>
</template>
<script>
import mixins from "../mixins";
import pay from "@/component/popup/pay/src/index";
import balance from "@/component/popup/balance/src/index";
import afterSale from "@/component/popup/after-sale/src/index";
import evaluate from "@/component/popup/evaluate/src/index";
import reason from "@/component/popup/reason/src/index";
import saletype from "@/component/popup/sale-type/src/index";
export default {
  components: {
    pay,
    balance,
    afterSale,
    evaluate,
    reason,
    saletype,
  },
  mixins,
};
</script>
<style scoped>
@import "../style.css";
</style>